import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComingComponent } from './coming.component';

const routes: Routes = [
  {
    path: '',
    component: ComingComponent
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class ComingRoutingModule {
}
