import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { environment } from '../environments/environment';
import { filter, map, mergeMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { OAuthService } from 'angular-oauth2-oidc';
import { PushPermissionComponent } from './dialogs/push-permission/push-permission.component';
import { SharedService } from './shared/shared.service';
import { StorageKeys } from './models/core/enums/storage-keys.enum';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { Urls } from './shared/urls';
import { UserService } from './services/user.service';
import { HttpClient } from '@angular/common/http';


declare var Push: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  cookieMessage:any;
  cookieDismiss:any;
  cookieLinkText:any;
  Frontend:boolean;

  showSpinner = true;
  private subscriptions: Subscription[] = [];
  private userToken: string = null;

  constructor(private activatedRoute: ActivatedRoute,
    private analytics: AngularFireAnalytics,
    private cloudMessaging: AngularFireMessaging,
    private matDialog: MatDialog,
    private oAuthService: OAuthService,
    private router: Router,
    private sharedService: SharedService,
    private title: Title,
    private urls: Urls,
    private userService: UserService,
    private HttpClient: HttpClient) {

    const subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data))
      .subscribe(event => {

        const pageTitle = event[`title`];
        this.title.setTitle(`chorchuri - ${pageTitle}`);
        this.analytics.setCurrentScreen(pageTitle, { global: true }).then();
        this.analytics.logEvent('screen_view', { screen_name: pageTitle }).then();
      });

      this.sharedService.updateSpinnerState(true);

    this.subscriptions.push(subscription);
  }

  ngOnInit(): void {
 
    this.sharedService.updateSpinnerState(true);

    this.getIPAddress();

    const subscription = this.sharedService.spinnerState
      .subscribe(status => this.showSpinner = status);

    this.subscriptions.push(subscription);

    this.configureOAuthService();
    this.configureFCMCloudMessaging();
   // this.checkForFCMPermission();

    // const subscription2 = this.sharedService.loggedInState
    //   .subscribe(state => {

    //     if (state) {
    //       this.checkForFCMPermission();
    //     }
    //   });

    // this.subscriptions.push(subscription2);
    this.cookieMessage = 'This website uses cookies to ensure you get the best experience on our website.';
    this.cookieLinkText = "Learn More";
    this.cookieDismiss = "OK!";

    let cc = window as any;
       cc.cookieconsent.initialise({
         palette: {
           popup: {
             background: "#618B01",
             text: "#fff"
           },
           button: {
             background: "#fff",
             text: "#fff"
           }
         },
         theme: "classic",
         content: {
           message: this.cookieMessage,
           dismiss: this.cookieDismiss,
           link: this.cookieLinkText,
           href: "#/cookie-policy" 
         }
       });
  }

  getIPAddress(): void {
    this.HttpClient.get("https://api64.ipify.org/?format=json").subscribe((res:any)=>{
      sessionStorage.setItem('ip', res.ip);
    });
  }

  // Configure OAuth Service
  // ----------------------------------------------------------------
  configureOAuthService(): void {

    this.oAuthService.tokenEndpoint = this.urls.OAuthLoginURL;
    this.oAuthService.clientId = 'consumerWeb';
    this.oAuthService.scope = 'read write';
    this.oAuthService.dummyClientSecret = 'consumerWeb@123';
    this.oAuthService.oidc = false;
    this.oAuthService.requireHttps = environment.requireHTTPS;
    this.oAuthService.setStorage(sessionStorage);
  }

  // Check if permission requested
  // ----------------------------------------------------------------
  checkForFCMPermission(): void {

    const pushPermission = localStorage.getItem(StorageKeys.NOTIFICATION_PERMISSION);

    if (pushPermission === null) {

      this.openPushPermissionDialog();
    } else if (pushPermission === 'yes') {

      this.requestPermission();
    }
  }

  // Configure Cloud Messaging
  // ----------------------------------------------------------------
  configureFCMCloudMessaging(): void {

    const subscription = this.cloudMessaging.messaging
      .subscribe(messaging => {
        // messaging.onMessage = messaging.onMessage.bind(messaging);
        messaging[`_next`] = payload => {
          console.log(payload);

          Push.create(payload.notification.title, {
            body: payload.notification.body,
            icon: './assets/img/brand/kidullan-logo.png',
            timeout: 8000,               // Timeout before notification closes automatically.
            vibrate: [100, 100, 100],    // An array of vibration pulses for mobile devices.
            onClick: () => {
              // Callback for when the notification is clicked.
              console.log(this);
            }
          });
        };

        messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
      });

    this.subscriptions.push(subscription);
  }

  // Open Push Permission Dialog
  // ----------------------------------------------------------------
  openPushPermissionDialog(): void {

    const subscription = this.matDialog.open(PushPermissionComponent, {
      id: 'confirmation-dialog',
      width: window.innerWidth > 768 ? '450px' : '95vw'
    })
      .afterClosed()
      .subscribe(result => {

        result ? this.requestPermission() : localStorage.setItem(StorageKeys.NOTIFICATION_PERMISSION, 'no');
      });

    this.subscriptions.push(subscription);
  }

  // Request For Notification Permission
  // ----------------------------------------------------------------
  requestPermission(): void {

    localStorage.setItem(StorageKeys.NOTIFICATION_PERMISSION, 'yes');

    const subscription = this.cloudMessaging.requestToken
      .subscribe(token => {
        this.userToken = token;
        this.updateUserFCMToken(this.userToken);
      }, error => console.error('Unable to get permission to notify.', error));

    this.subscriptions.push(subscription);
  }

  // Update FCM token on Server
  // ----------------------------------------------------------------
  private updateUserFCMToken(token: string): void {

    const accessToken = this.sharedService.appStorage.getItem(StorageKeys.ACCESS_TOKEN);

    if (accessToken !== null) {
      const subscription = this.userService.updateUserFCMToken(token)
        .subscribe(() => console.log('FCM Updated'));
      this.subscriptions.push(subscription);
    }
  }

  ngOnDestroy(): void {

    SharedService.unsubscribe(this.subscriptions);
  }
}
