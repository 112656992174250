import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SuccessResponse } from '../models/response-types/success-response';
import { Urls } from '../shared/urls';
import { User } from '../models/user/user';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {

  constructor( private httpClient: HttpClient, private urls: Urls ) {
  }

  // POST: - Register User
  // ----------------------------------------------------------------
  registerConsumer(userDetails: User): Observable<User> {
    // console.log('RegisterCOnsumer:',userDetails);

    return this.httpClient
      .post<User>(this.urls.registerUserURL, userDetails);
  }

  // POST: - Login Access Update
  // ----------------------------------------------------------------
  loginAccessUpdate(browserInfo: any): Observable<any> {

    return this.httpClient
      .post<any>(this.urls.loginAccessUpdateURL, browserInfo);
  }

  // POST: - Logout User from OAuth2
  // ----------------------------------------------------------------
  OAuthLogout(): Observable<any> {

    return this.httpClient
      .post<any>(this.urls.OAuthLogoutURL, null);
  }

  // POST: - Send OTP to entered mobile number
  // ----------------------------------------------------------------
  sendOTP(mobileNumber: string, userD: any): Observable<any> {

    const params: HttpParams = new HttpParams()
      .set('mobileNumber', mobileNumber);

    return this.httpClient
      .post<any>(this.urls.sendOTPURL, {userD}, { params });
  }

  // GET: - Verify OTP against mobile number
  // ----------------------------------------------------------------
  verifyOTP(mobileNumber: string, otpText: string): Observable<any> {

    const params: HttpParams = new HttpParams()
      .set('mobileNumber', mobileNumber)
      .set('otpText', otpText);

    return this.httpClient
      .post<any>(this.urls.verifyOTPURL, null,  { params });
  }

  // POST: - Send Forgot Password Reset Link
  // ----------------------------------------------------------------
  sendForgotPasswordResetLink(mobile: string): Observable<SuccessResponse> {

    const params: HttpParams = new HttpParams()
      .set('mobile', mobile);

    return this.httpClient
      .post<SuccessResponse>(this.urls.sendRequestForForgottenPasswordURL, null, { params });
  }

  // POST: - Reset Password from Forgot Password Link
  // ----------------------------------------------------------------
  resetForgottenPassword(mobileNumber:any, password:string, password_confirmation:string) {

    const params: HttpParams = new HttpParams()
      .set('mobileNumber', mobileNumber)
      .set('password', password)
      .set('password_confirmation', password_confirmation);

    return this.httpClient
      .post(this.urls.resetForgottenPasswordURL, null, { params });
  }

  checkUerLoginApi(): Observable<any> {
    let ip = sessionStorage.getItem('ip');
    let login_token = localStorage.getItem('login_token');
    if(localStorage.getItem('regID') != undefined)
    {
      var userId = localStorage.getItem('regID');
    }
    else
    {
      var userId = localStorage.getItem('user_id');

    }
    const params = new HttpParams()
    .set('ip', ip)
    .set('token', login_token)
    .set('id', userId);
    return this.httpClient
      .get<any>(this.urls.checkUserLogin,{ params });
  }
}
