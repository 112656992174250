import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PageableResponse } from '../models/response-types/pageable-response';
import { UpcomingMedia } from '../models/upcoming-media/upcoming-media';
import { Urls } from '../shared/urls';

@Injectable({
  providedIn: 'root'
})
export class UpcomingMediaService {

  constructor( private httpClient: HttpClient, private urls: Urls ) {
  }

  // Fetch for Upcoming Media List
  // ----------------------------------------------------------------
  fetchForUpcomingMediaList(): Observable<PageableResponse<UpcomingMedia>> {

    return this.httpClient
      .get<PageableResponse<UpcomingMedia>>(this.urls.fetchForUpcomingMediaList);
  }
}
