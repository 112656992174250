import { Pipe, PipeTransform } from '@angular/core';
import { SubscriptionPackage } from '../models/subscription/subscription-package';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(subscriptionPackage: SubscriptionPackage, ...args: any[]): any {
    let currency;
    let price;

    if(subscriptionPackage.chk_country == 'India')
    {
      currency = subscriptionPackage.currency;
      price = 'INR';
    }
    else
    {
      currency = subscriptionPackage.symbol;
      price = subscriptionPackage.code;
    }

   // const currency = subscriptionPackage.currency;

    if ( subscriptionPackage.listedPrice < subscriptionPackage.amount ) {

      return `<ul class="deletedPrice1">
      <li><s> <small>${ currency }</small> ${ subscriptionPackage.amount }</s></li>
      <li> <small>${ currency }</small> ${ subscriptionPackage.listedPrice }</li>
      </ul> `;
    }

    return `<small class="currency">${ currency }</small> ${ subscriptionPackage.listedPrice }`;
  }



}


  
// <p class="deletedPrice">
// <del>
//   <small>${ currency }</small> ${ subscriptionPackage.amount }
// </del> </p> 
// <br>
// <h3 class="actualPrice">
// <small class="currency ">${ currency }</small> ${ subscriptionPackage.listedPrice } </h3>

// 