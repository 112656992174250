import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AppRoutes } from '../../models/core/enums/app-routes.enum';
import { AppStoreDialogComponent } from '../../dialogs/app-store-dialog/app-store-dialog.component';
import { MatDialog } from '@angular/material';
import { ViewportScroller } from '@angular/common';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  appRoutes = AppRoutes;
  hideArrow: boolean = false;

  constructor(private matDialog: MatDialog, private viewportScroller: ViewportScroller, private router: Router) {
  }

   // Smooth Scroll option
  // ---------------------------------------------------------------
  onClickTopScroll(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit(): void {
    // console.log("URL",this.appRoutes);
    
    
    this.router.events.subscribe(
      (event: any) => {
        if(this.appRoutes.SUBSCRIBE === this.router.url || this.appRoutes.SUPPORT === this.router.url){
            this.hideArrow = true;
        } else {
          this.hideArrow = false;
        }
      }
    );
    // Show App Store Links on Mobile devices.
    if (window.innerWidth <= 768) {
      this.matDialog.open(AppStoreDialogComponent, {
        panelClass: 'app-store-dialog',
        minWidth: window.innerWidth <= 500 ? '96vw' : '50vw',
        position: { bottom: 'bottom' },
        hasBackdrop: false
      });
    }
  }

}
