import { NgModule } from '@angular/core';
import { ComingComponent } from './coming.component';

import { CommonModule } from '@angular/common';
import { ComingRoutingModule } from './coming-routing.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SharedModule } from '../../shared/shared.module';

import { MediaService } from '../../services/media.service';

@NgModule({
  declarations: [ ComingComponent ],
  imports: [
    CommonModule,
    ComingRoutingModule,
    LazyLoadImageModule,
    SharedModule
  ],
  providers: [ MediaService ]
})
export class ComingModule {
}
