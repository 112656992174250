import { NgModule } from '@angular/core';
import { PushPermissionComponent } from './push-permission.component';
import {RouterModule} from '@angular/router';


import { CommonModule } from '@angular/common';
import { MatButtonModule, MatDialogModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';


@NgModule({
  declarations: [ PushPermissionComponent ],
  entryComponents: [ PushPermissionComponent ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    RouterModule
    
  ]
})
export class PushPermissionModule {


}
