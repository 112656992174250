import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    children: [
      {
        path: 'home',
        data: { title: 'Home' },
        loadChildren: () => import('./pages/home/home.module').then( m => m.HomeModule )
      },
      {
        path: 'upcoming',
        data: { title: 'Upcoming' },
        loadChildren: () => import('./pages/upcoming/upcoming.module').then( m => m.UpcomingModule )
      },
      {
        path: 'browse',
        data: { title: 'Browse' },
        loadChildren: () => import('./pages/browse/browse.module').then( m => m.BrowseModule )
      },

      {
        path: 'blog',
        data: { title: 'Blog' },
        loadChildren: () => import('./pages/blog/blog.module').then( m => m.BlogModule )
      },


      {
        path: 'post',
        data: { title: 'Blog Post' },
        loadChildren: () => import('./pages/ui-content/post/post.module').then( m => m.PostModule )
      },

      {
        path: 'coming',
        data: { title: 'upcoming' },
        loadChildren: () => import('./pages/coming/coming.module').then( m => m.ComingModule )
      },
      {
        path: 'category-browse',
        data: { title: 'Category Browse' },
        loadChildren: () => import('./pages/category-browse/category-browse.module').then( m => m.CategoryBrowseModule )
      },
      {
        path: 'media',
        data: { title: 'Media' },
        loadChildren: () => import('./pages/media-landing/media-landing.module').then( m => m.MediaLandingModule )
      },

      {
        path: 'trailer',
        data: { title: 'Trailer' },
        loadChildren: () => import('./pages/trailer/trailer.module').then( m => m.TrailerModule )
      },

      {
        path: 'subscribe',
        data: { title: 'Subscribe' },
        loadChildren: () => import('./pages/subscribe/subscribe.module').then( m => m.SubscribeModule )
      },
      {
        path: 'login',
        data: { title: 'Login' },
        loadChildren: () => import('./pages/auth/auth.module').then( m => m.AuthModule )
      },
      {
        path: 'register',
        data: {title: 'Register'},
        loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule)
      },
      {
        path: 'forgot-password',
        data: { title: 'Forgot Password' },
        loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordModule )
      },
      {
        path: 'reset',
        data: { title: 'Reset Password' },
        loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordModule )
      },
      {
        path: 'profile-settings',
        data: { title: 'Profile Settings' },
        loadChildren: () => import('./pages/profile-settings/profile-settings.module').then( m => m.ProfileSettingsModule )
      },
      {
        path: 'support',
        data: { title: 'Support' },
        loadChildren: () => import('./pages/support/support.module').then( m => m.SupportModule )
      },
      {
        path: 'faq',
        data: { title: 'FAQs' },
        loadChildren: () => import('./pages/faq/faq.module').then( m => m.FaqModule )
      },
      {                    
        path: 'about-us',
        data: { title: 'About Us' },
        loadChildren: () => import('./pages/ui-content/about-us/about-us.module').then( m => m.AboutUsModule )
      },
      {
        path: 'contact-us',
        data: { title: 'Contact us'},
        loadChildren: () => import('./pages/ui-content/contact-us/contact-us.module').then( m => m.ContactUsModule )
      },
      {
        path: 'terms-and-conditions',
        data: { title: 'Terms And Conditions' },
        loadChildren: () => import('./pages/ui-content/terms-and-conditions/terms-and-conditions.module').then( m => m.TermsAndConditionsModule )
      },
      {
        path: 'privacy-policy',
        data: { title: 'Privacy Policy' },
        loadChildren: () => import('./pages/ui-content/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyModule )
      },
      {
        path: 'delete-user-policy',
        data: { title: 'Delete User Policy' },
        loadChildren: () => import('./pages/ui-content/delete-user-fb/delete-user-policy.module').then( m => m.DeleteUserPolicyModule )
      },
      {
        path: 'refund-policy',
        data: { title: 'Refund Policy' },
        loadChildren: () => import('./pages/ui-content/refund-policy/refund-policy.module').then( m => m.RefundPolicyModule )
      },
      {
        path: 'maintenance',
        data: { title: 'Maintenance' },
        loadChildren: () => import('./pages/maintenance/maintenance.module').then( m => m.MaintenanceModule )
      },
      {
        path: 'seasons-description',
        data: { title: 'Season Details' },
        loadChildren: () => import('./pages/season-description/season-description.module').then( m => m.SeasonDescriptionModule )
      },
      {
        path: 'links',
        data: { title: 'Redirecting...' },
        loadChildren: () => import('./pages/links/links.module').then( m => m.LinksModule )
      },
      {
        path: 'submit-content',
        data: {title: 'Submit Content'},
        loadChildren: () => import('./pages/submit-content1/submit-content1.module').then(m => m.SubmitContent1Module)
      },
      {
        path: 'cookie-policy',
        data: {title: 'Cookie Policy'},
        loadChildren: () => import('./pages/ui-content/cookies-policy/cookie-policy.module').then(m => m.CookiePolicyModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full'
  }
];

@NgModule( {
  imports: [ RouterModule.forRoot( routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'top',
    anchorScrolling:'enabled',
    useHash: true
  } ) ],
  exports: [ RouterModule ]
} )
export class AppRoutingModule {
}
